import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import _ from 'lodash';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';

import Layout from '../components/Layout';
import Content from '../components/Content';

const Container = styled.div`
  text-align: center;
`;

function formatDate(date:Date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

const HomePage = () => {
  const data = useStaticQuery(
    graphql`
      query TodayPage {
        allHtml(sort: { fields: [relativeDirectory], order: ASC }) {
          nodes {
            id
            relativeDirectory
            frontmatter {
              date(formatString: "YYYY-MM-DD")
            }
          }
        }
      }
    `
  );

  const {
    allHtml: { nodes },
  } = data;

  const [countDownCount,setCountDownCount] = useState(1);
  const [archiveLink,setArchiveLink] = useState('');
  useEffect(() => {
    if (countDownCount==4){
      navigate(archiveLink);
      return;
    }
    setTimeout(()=>{
    setCountDownCount(countDownCount+1);

    },1000);
  }, [countDownCount]);

  useEffect(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear()-20);
    const str = formatDate(date);
    let i=0;
    for ( i=0;i<nodes.length-1;i++){
      if (str<=nodes[i].frontmatter.date){
        break;
      }
    }
    setArchiveLink(`/archive/${nodes[i].frontmatter.date}`);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Legacy of Steel</title>
      </Helmet>
      <Content>
        <Container>
          <h1><Link to={archiveLink}>20 Years ago {`${'.'.repeat(countDownCount)}`}</Link></h1>
        </Container>
      </Content>
    </Layout>
  );
};
export default HomePage;
